export const ROOT_PATH = "/";

export const LOGIN_PATH = `${ROOT_PATH}login`;
export const DASHBOARD_PATH = `${ROOT_PATH}dashboard`;

export const PAGE_404_PATH = `${ROOT_PATH}404`;
export const PAGE_403_PATH = `${ROOT_PATH}403`;
export const PAGE_500_PATH = `${ROOT_PATH}500`;

// user
export const ROLE_LIST_VIEW_PATH = `${ROOT_PATH}role-list`;
export const CREATE_ROLE_PATH = `${ROOT_PATH}create-role`;
export const UPDATE_ROLE_PATH = `${ROOT_PATH}update-role`;

// user
export const USER_LIST_VIEW_PATH = `${ROOT_PATH}user-list`;
export const CREATE_USER_PATH = `${ROOT_PATH}create-user`;
export const UPDATE_USER_PATH = `${ROOT_PATH}update-user`;
export const PROFILE_PATH = `${ROOT_PATH}profile`;


// Restaurant
export const RESTAURANT_LIST_VIEW_PATH = `${ROOT_PATH}restaurant-list`;
export const CREATE_RESTAURANT_PATH = `${ROOT_PATH}create-restaurant`;
export const UPDATE_RESTAURANT_PATH = `${ROOT_PATH}update-restaurant`;
export const STATISTICS_LIST_RESTAURANT_PATH = `${ROOT_PATH}staistics-restaurant`;
export const VIEW_RESTAURANT_PATH = `${ROOT_PATH}view-restaurant`;

// assign restaurant role
export const ASSIGN_RESTAURANT_ROLE = `${ROOT_PATH}assign-restaurant-role`;

// restaurant config
export const RESTAURANT_CONFIG_LIST_PATH = `${ROOT_PATH}restaurant-config-list`;
export const CREATE_RESTAURANT_CONFIG_PATH = `${ROOT_PATH}create-restaurant-config`;
export const UPDATE_RESTAURANT_CONFIG_PATH = `${ROOT_PATH}update-restaurant-config`;

export const COUPON_LIST_VIEW_PATH = `${ROOT_PATH}coupon-list`;
export const CREATE_COUPON_PATH = `${ROOT_PATH}create-coupon`;
export const UPDATE_COUPON_PATH = `${ROOT_PATH}update-coupon`;

// System Config
export const SYSTEM_CONFIG_LIST_VIEW_PATH = `${ROOT_PATH}system-config-list`;
export const CREATE_SYSTEM_CONFIG_PATH = `${ROOT_PATH}create-system-config`;
export const UPDATE_SYSTEM_CONFIG_PATH = `${ROOT_PATH}update-system-config`;
